/* const body = document.querySelector("body");
const footer = document.querySelector("footer");
const header = document.querySelector("header");
const nav = header.querySelector("header > :first-child");

const windowHeight = window.innerHeight; */

let header_sticky_init = () => {
	const middleVerticlaHeight = windowHeight / 2;
	const sectionContattaci = body.querySelector("#contattaci");
	const contattaciFooterHeight =
		sectionContattaci.offsetHeight + footer.offsetHeight;
	const footerForm =
		body.offsetHeight - (sectionContattaci.offsetHeight + footer.offsetHeight);

	if (768 > window.innerWidth) {
		header.classList.add("fixed-bottom");
		header.classList.remove("fixed-top");
	} else {
		header.classList.remove("fixed-bottom");
		header.classList.add("fixed-top");
	}

	if (window.scrollY >= middleVerticlaHeight) {
		header.classList.add("active");
		header.classList.remove("pe-none");
	} else {
		header.classList.remove("active");
		header.classList.add("pe-none");
	}
};

const headerScroll = () => {
	if (window.scrollY > 90) {
		header.classList.add("scrolled");
	} else {
		header.classList.remove("scrolled");
	}
};

const headerSectionShadow = () => {
	let headerHeight = header.offsetHeight;
	const gradientTop = document.querySelector(
		".fancy-list__sticky-gradient>.gradient.top"
	);

	headerHeight = headerHeight - 1;

	gradientTop.style.top = `${headerHeight}px`;
};

// swiper
const swiperLogo = new Swiper(".swiper.client-logos", {
	// Optional parameters
	loop: true,
	slidesPerView: 1,
	autoHeight: false,
	spaceBetween: 10,
	centeredSlides: false,

	autoplay: {
		delay: 2500,
		disableOnInteraction: false,
	},

	// If we need pagination
	pagination: {
		el: "carousel-pagination",
	},

	// Navigation arrows
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},

	// And if we need scrollbar
	scrollbar: {
		el: ".swiper-scrollbar",
	},

	breakpoints: {
		768: {
			slidesPerView: 2,
			spaceBetween: 20,
		},

		992: {
			slidesPerView: 5,
			spaceBetween: 40,
		},
	},
});

const swiperReview = new Swiper(".swiper.slider-reviews", {
	// Optional parameters
	loop: false,
	slidesPerView: 1.1,
	autoHeight: false,
	spaceBetween: 10,
	centeredSlides: false,

	// If we need pagination
	pagination: {
		el: "carousel-pagination",
	},

	breakpoints: {
		768: {
			slidesPerView: 1.2,
			spaceBetween: 10,
		},

		992: {
			slidesPerView: 1.5,
			spaceBetween: 50,
		},
	},
});

const ctaFixed = () => {
	const fixCta = document.querySelector(".fix-cta");
	const cloud = fixCta.querySelector(".cloud");
	const callMeBack = fixCta.querySelector(".call-me-back");
	const phone = fixCta.querySelector(".phone");

	setTimeout(() => {
		if (!callMeBack.classList.contains("active")) {
			cloud.classList.add("active");
		}
	}, 4000);

	phone.addEventListener("click", () => {
		cloud.classList.remove("active");
		callMeBack.classList.toggle("active");
	});
};

// parallax effect
const parallaxEffect = () => {
	// Also can pass in optional settings block
	new Rellax(".canvas-item > div", {
		center: true,
		round: true,
	});
};

// calendar height
const calendarSameHeight = () => {
	const calendarHeight = document.querySelector(
		".calendar.select-day"
	).offsetHeight;

	// hour selector container
	const hourSelectorContainer = document.querySelector(".calendar-inner.grid");
	const hourSelectorParagraph = hourSelectorContainer.querySelector(
		".fw-semibold.text-center.mb-5"
	);
	const hourSelectorList = hourSelectorContainer.querySelector(
		".calendar-inner.grid > .select-hour"
	);

	const hourSelectorParagraphHeight = hourSelectorParagraph.offsetHeight;
	const hourSelectorParagraphHeightMb = parseInt(
		window
			.getComputedStyle(hourSelectorParagraph)
			.getPropertyValue("margin-bottom")
	);

	const hourSelectorContainerHeight = hourSelectorContainer.offsetHeight;
	const hourSelectorContainerHeightPt = parseInt(
		window
			.getComputedStyle(hourSelectorContainer)
			.getPropertyValue("padding-top")
	);

	const hourSelectorContainerHeightPb = parseInt(
		window
			.getComputedStyle(hourSelectorContainer)
			.getPropertyValue("padding-bottom")
	);

	let heightCalculation =
		hourSelectorParagraphHeight +
		hourSelectorParagraphHeightMb +
		hourSelectorContainerHeightPt +
		hourSelectorContainerHeightPb;
	heightCalculation = calendarHeight - heightCalculation;

	hourSelectorList.style.height = `${heightCalculation}px`;
};

// calendar
//check the console for date click event
//Fixed day highlight
//Added previous month and next month view

// https://codepen.io/alvarotrigo/pen/bGLpROa
const bookingWrapper = document.querySelector(".booking-wrapper");

const calendarInputWrapper = document.querySelector("#calendar-input-wrapper");

const formWrapper = document.querySelector("#input-wrapper");
const formWrapperBackButton = formWrapper.querySelector("#backButton");
const formCheckInput = document.querySelectorAll(".form-check-input");

formWrapperBackButton.addEventListener("click", () => {
	// hide form
	formWrapper.classList.remove("active");

	// show calendar - input

	calendarInputWrapper.classList.add("active");

	bookingWrapper.style.height = `${calendarInputWrapper.firstElementChild.offsetHeight}px`;

	formCheckInput.forEach((singleInput) => {
		singleInput.checked = false;
	});
});

function CalendarControl() {
	// selezione ore
	const hourContainer = document.querySelector(".select-hour");
	const hourSelector = hourContainer.querySelectorAll(".form-check-input");

	// selezione calendario
	const calendar = new Date();
	const calendarControl = {
		localDate: new Date(),
		prevMonthLastDate: null,
		calWeekDays: ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"],
		calMonthName: [
			"Gennaio",
			"Febbraio",
			"Marzo",
			"Aprile",
			"Maggio",
			"Giugno",
			"Luglio",
			"Agosto",
			"Settembre",
			"Ottobre",
			"Novembre",
			"Dicembre",
		],

		daysInMonth: function (month, year) {
			return new Date(year, month, 0).getDate();
		},
		firstDay: function () {
			return new Date(calendar.getFullYear(), calendar.getMonth(), 0);
		},
		lastDay: function () {
			return new Date(calendar.getFullYear(), calendar.getMonth() + 1, 0);
		},
		firstDayNumber: function () {
			return calendarControl.firstDay().getDay() + 1;
		},
		lastDayNumber: function () {
			return calendarControl.lastDay().getDay() + 1;
		},
		getPreviousMonthLastDate: function () {
			let lastDate = new Date(
				calendar.getFullYear(),
				calendar.getMonth(),
				0
			).getDate();
			return lastDate;
		},
		navigateToPreviousMonth: function () {
			calendar.setMonth(calendar.getMonth() - 1);
			calendarControl.attachEventsOnNextPrev();
			calendarControl.disabledOldMonth();
		},
		navigateToNextMonth: function () {
			calendar.setMonth(calendar.getMonth() + 1);
			calendarControl.attachEventsOnNextPrev();
			calendarControl.disabledOldMonth();
		},
		navigateToCurrentMonth: function () {
			let currentMonth = calendarControl.localDate.getMonth();
			let currentYear = calendarControl.localDate.getFullYear();
			calendar.setMonth(currentMonth);
			calendar.setYear(currentYear);
			calendarControl.attachEventsOnNextPrev();
		},

		displayYear: function () {
			let yearLabel = document.querySelector(".calendar .calendar-year-label");
			yearLabel.innerHTML = calendar.getFullYear();
		},
		displayMonth: function () {
			let monthLabel = document.querySelector(
				".calendar .calendar-month-label"
			);

			monthLabel.innerHTML = calendarControl.calMonthName[calendar.getMonth()];
		},
		selectDate: function (e) {
			// console.log(`${e.target.textContent} ${calendarControl.calMonthName[calendar.getMonth()]} ${calendar.getFullYear()}`);

			let allDaysWork = [
				...document.querySelectorAll(
					".calendar .calendar-days .number-item:not(.weekend):not(.prev-days):not(.calendar-today)"
				),
			];

			allDaysWork.forEach((el) => {
				el.classList.remove("selected");

				// first days
				if (
					e.target.parentElement.getAttribute("data-num") ==
						el.getAttribute("data-num") &&
					!e.target.hasAttribute("disabled")
				) {
					el.classList.remove("selected");

					hourSelector.forEach((el) => {
						el.setAttribute("disabled", "");
					});

					if (e.target.classList.contains("first-days")) {
						// click first days
						e.target.parentElement.classList.add("selected");

						allDaysWork.forEach((singleDays) => {
							let firstDaySelectedHour = "";
							let firstSelectedDay = "";
							let firstDaySelectedMonth = "";
							let firstDaySelectedYear = "";

							if (singleDays.classList.contains("selected")) {
								hourSelector.forEach((singleHour) => {
									if (
										singleHour.id == "09-30" ||
										singleHour.id == "11-30" ||
										singleHour.id == "14-30" ||
										singleHour.id == "16-30"
									) {
										singleHour.removeAttribute("disabled");
									}

									singleHour.addEventListener("click", (inputClick) => {
										if (inputClick.target.checked) {
											firstSelectedDay =
												e.target.parentElement.getAttribute("data-num");
											firstDaySelectedHour =
												inputClick.target.getAttribute("value");
											firstDaySelectedMonth =
												calendarControl.calMonthName[calendar.getMonth()];
											firstDaySelectedYear = document.querySelector(
												".calendar .calendar-year-label"
											).innerHTML;
											console.log(
												`${firstSelectedDay}/${firstDaySelectedMonth}/${firstDaySelectedYear} - ${firstDaySelectedHour}`
											);

											// formWrapper.classList.remove("d-none");
											formWrapper.classList.add("active");

											calendarInputWrapper.classList.remove("active");

											bookingWrapper.style.height = `${formWrapper.firstElementChild.offsetHeight}px`;
										}
									});
								});
							}
						});
					} else {
						el.classList.remove("selected");

						// click no first days
						e.target.parentElement.classList.add("selected");

						hourSelector.forEach((el) => {
							el.removeAttribute("disabled");
						});

						allDaysWork.forEach((singleDays) => {
							let multipleDaysHour = "";
							let multipleDaysSelectedDay = "";
							let multipleDaysSelectedMonth = "";
							let multipleDaysSelectedYear = "";

							if (singleDays.classList.contains("selected")) {
								hourSelector.forEach((singleHour) => {
									multipleDaysSelectedDay =
										e.target.parentElement.getAttribute("data-num");

									singleHour.addEventListener("click", (inputClick) => {
										if (inputClick.target.checked) {
											multipleDaysHour =
												inputClick.target.getAttribute("value");
											multipleDaysSelectedMonth =
												calendarControl.calMonthName[calendar.getMonth()];
											multipleDaysSelectedYear = document.querySelector(
												".calendar .calendar-year-label"
											).innerHTML;
											console.log(
												`${multipleDaysSelectedDay}/${multipleDaysSelectedMonth}/${multipleDaysSelectedYear} - ${multipleDaysHour}`
											);

											// formWrapper.classList.remove("d-none");
											formWrapper.classList.add("active");

											calendarInputWrapper.classList.remove("active");

											bookingWrapper.style.height = `${formWrapper.firstElementChild.offsetHeight}px`;
										}
									});
								});
							}
						});
					}
				}
			});
		},

		plotSelectors: function () {
			document.querySelector(
				".calendar"
			).innerHTML += `<div class="calendar-inner">
		  <div class="d-flex justify-content-center">
			<div class="calendar-icon">
			<img src="./img/calendar-icon.svg" class="img-fluid">
		  </div>
		  </div>
			<div class="text-center fs-4 fw-bold mt-3">Seleziona la data</div>
		  <div class="calendar-controls my-4">
          <div class="calendar-prev"><span role="button"><svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128"><path fill="#fff" d="M88.2 3.8L35.8 56.23 28 64l7.8 7.78 52.4 52.4 9.78-7.76L45.58 64l52.4-52.4z"/></svg></span></div>
          <div class="calendar-year-month">
          <div class="calendar-month-label"></div>
		  <span>-</span>
		  <div class="calendar-year-label"></div>
          </div>
          <div class="calendar-next"><span role="button"><svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128"><path fill="#fff" d="M38.8 124.2l52.4-52.42L99 64l-7.77-7.78-52.4-52.4-9.8 7.77L81.44 64 29 116.42z"/></svg></span></div>
          </div>
          <div class="calendar-body"><div class="calendar-name"></div><div class="calendar-days"></div></div></div>`;
		},
		plotDayNames: function () {
			for (let i = 0; i < calendarControl.calWeekDays.length; i++) {
				document.querySelector(
					".calendar .calendar-body .calendar-name"
				).innerHTML += `<div>${calendarControl.calWeekDays[i]}</div>`;
			}
		},
		plotDates: function () {
			document.querySelector(".calendar .calendar-days").innerHTML = "";
			// calendarControl.plotDayNames();
			calendarControl.displayMonth();
			calendarControl.displayYear();
			let count = 1;
			let prevDateCount = 0;

			calendarControl.prevMonthLastDate =
				calendarControl.getPreviousMonthLastDate();
			let prevMonthDatesArray = [];
			let calendarDays = calendarControl.daysInMonth(
				calendar.getMonth() + 1,
				calendar.getFullYear()
			);
			// dates of current month
			for (let i = 1; i < calendarDays; i++) {
				if (i < calendarControl.firstDayNumber()) {
					prevDateCount += 1;
					document.querySelector(
						".calendar .calendar-days"
					).innerHTML += `<div class="prev-dates"></div>`;
					prevMonthDatesArray.push(calendarControl.prevMonthLastDate--);
				} else {
					document.querySelector(
						".calendar .calendar-days"
					).innerHTML += `<div class="number-item" data-num=${count}><span role="button" class="dateNumber">${count++}</span></div>`;
				}
			}
			//remaining dates after month dates
			for (let j = 0; j < prevDateCount + 1; j++) {
				document.querySelector(
					".calendar .calendar-days"
				).innerHTML += `<div class="number-item" data-num=${count}><span role="button" class="dateNumber">${count++}</span></div>`;
			}
			calendarControl.highlightToday();
			calendarControl.plotPrevMonthDates(prevMonthDatesArray);
			calendarControl.plotNextMonthDates();
		},
		attachEvents: function () {
			let prevBtn = document.querySelector(".calendar .calendar-prev span");
			let nextBtn = document.querySelector(".calendar .calendar-next span");
			// let todayDate = document.querySelector(".calendar .calendar-today-date");
			let dateNumber = document.querySelectorAll(".calendar .dateNumber");
			prevBtn.addEventListener(
				"click",
				calendarControl.navigateToPreviousMonth
			);
			nextBtn.addEventListener("click", calendarControl.navigateToNextMonth);
			// todayDate.addEventListener("click", calendarControl.navigateToCurrentMonth);
			for (var i = 0; i < dateNumber.length; i++) {
				dateNumber[i].addEventListener(
					"click",
					calendarControl.selectDate,
					true
				);
			}
		},
		highlightToday: function () {
			let currentMonth = calendarControl.localDate.getMonth() + 1;
			let changedMonth = calendar.getMonth() + 1;
			let currentYear = calendarControl.localDate.getFullYear();
			let changedYear = calendar.getFullYear();
			if (
				currentYear === changedYear &&
				currentMonth === changedMonth &&
				document.querySelectorAll(".number-item")
			) {
				document
					.querySelectorAll(".number-item")
					[calendar.getDate() - 1].classList.add("calendar-today");
			}
		},
		plotPrevMonthDates: function (dates) {
			dates.reverse();
			for (let i = 0; i < dates.length; i++) {
				if (document.querySelectorAll(".prev-dates")) {
					document.querySelectorAll(".prev-dates")[i].textContent = dates[i];
				}
			}
		},
		plotNextMonthDates: function () {
			let childElemCount =
				document.querySelector(".calendar-days").childElementCount;

			//6 lines
			if (childElemCount > 37) {
				let diff = 44 - childElemCount;
				calendarControl.loopThroughNextDays(diff);
			}

			//5 lines
			if (childElemCount > 28 && childElemCount <= 37) {
				let diff = 35 - childElemCount;
				calendarControl.loopThroughNextDays(35 - childElemCount);
			}
		},
		loopThroughNextDays: function (count) {
			if (count > 0) {
				for (let i = 1; i <= count; i++) {
					document.querySelector(
						".calendar-days"
					).innerHTML += `<div class="next-dates">${i}</div>`;
				}
			}
		},
		addWeekEndDay: function () {
			let allCalendaryBody = document.querySelectorAll(".calendar-days > div");

			for (let i = 0; i < allCalendaryBody.length; i++) {
				if (
					i == 5 ||
					i == 6 ||
					i == 12 ||
					i == 13 ||
					i == 19 ||
					i == 20 ||
					i == 26 ||
					i == 27 ||
					i == 33 ||
					i == 34 ||
					i == 41 ||
					i == 40 ||
					i == 47 ||
					i == 48
				) {
					allCalendaryBody[i].classList.add("weekend");
				}
			}
		},
		disabledOldMonth: function () {
			let prevBtn = document.querySelector(".calendar .calendar-prev");
			let currentMonth = new Date().getMonth();
			let currentYear = new Date().getFullYear();

			if (
				calendarControl.calMonthName[currentMonth] ==
					calendarControl.calMonthName[calendar.getMonth()] &&
				currentYear == calendar.getFullYear()
			) {
				prevBtn.classList.add("disabled");
			} else {
				prevBtn.classList.remove("disabled");
			}
		},
		disabledOldDays: function () {
			let currentMonth = new Date().getMonth();
			let currentYear = new Date().getFullYear();

			let currentDate = document.querySelector(
				".calendar .calendar-days .number-item.calendar-today"
			);
			let allDays = [
				...document.querySelectorAll(".calendar .calendar-days .number-item"),
			];
			let allDaysWeekEnd = [
				...document.querySelectorAll(
					".calendar .calendar-days .number-item.weekend"
				),
			];

			allDaysWeekEnd.forEach((el) => {
				el.firstElementChild.setAttribute("disabled", "");
				el.classList.add("disabled");
			});

			if (currentDate) {
				let currentDateData = currentDate.getAttribute("data-num");
				let prevDays = allDays.slice(0, currentDateData);

				prevDays.forEach((el) => {
					el.firstElementChild.setAttribute("disabled", "");
					el.classList.add("disabled");

					if (!el.classList.contains("calendar-today")) {
						el.classList.add("prev-days");
					}
				});
			}

			let allDaysWork = [
				...document.querySelectorAll(
					".calendar .calendar-days .number-item:not(.weekend):not(.prev-days):not(.calendar-today)"
				),
			];

			// disable custom days
			if (currentDate) {
				let disableCustomDays = allDaysWork.splice(0, 1);
				disableCustomDays.forEach((el) => {
					el.firstElementChild.setAttribute("disabled", "");
					el.classList.add("disabled");
				});

				let disableThreeDays = allDaysWork.splice(0, 2);

				disableThreeDays.forEach((el) => {
					el.firstElementChild.classList.add("first-days");
				});
			}

			let currentDay = new Date().getDate();

			if (!currentDate) {
				if (currentDay >= 29 && currentDay <= 31) {
					if (currentDay == 29) {
						let disableCustomDays = allDaysWork.splice(0, 0);
						disableCustomDays.forEach((el) => {
							el.firstElementChild.setAttribute("disabled", "");
							el.classList.add("disabled");
						});

						let disableThreeDays = allDaysWork.splice(0, 3);

						disableThreeDays.forEach((el) => {
							el.firstElementChild.classList.add("first-days");
						});
					}

					if (currentDay == 30) {
						let disableCustomDays = allDaysWork.splice(0, 1);
						disableCustomDays.forEach((el) => {
							el.firstElementChild.setAttribute("disabled", "");
							el.classList.add("disabled");
						});

						let disableThreeDays = allDaysWork.splice(0, 3);

						disableThreeDays.forEach((el) => {
							el.firstElementChild.classList.add("first-days");
						});
					}

					if (currentDay == 31) {
						let disableCustomDays = allDaysWork.splice(0, 1);
						disableCustomDays.forEach((el) => {
							el.firstElementChild.setAttribute("disabled", "");
							el.classList.add("disabled");
						});

						let disableThreeDays = allDaysWork.splice(0, 3);

						disableThreeDays.forEach((el) => {
							el.firstElementChild.classList.add("first-days");
						});
					}
				}

				console.log(currentDay);
			}

			// Disabilita 8, 26 dicembre 2022
			if (calendar.getMonth() == 11 && calendar.getFullYear() == 2022) {
				document
					.querySelector(`.number-item[data-num="8"]`)
					.firstElementChild.setAttribute("disabled", "");
				document
					.querySelector(`.number-item[data-num="26"]`)
					.firstElementChild.setAttribute("disabled", "");
			}

			// Disabilita 6 gennaio 2023
			if (calendar.getMonth() == 0 && calendar.getFullYear() == 2023) {
				document
					.querySelector(`.number-item[data-num="6"]`)
					.firstElementChild.setAttribute("disabled", "");
			}

			// Disabilita 10, 25 aprile 2023
			if (calendar.getMonth() == 3 && calendar.getFullYear() == 2023) {
				document
					.querySelector(`.number-item[data-num="10"]`)
					.firstElementChild.setAttribute("disabled", "");

				document
					.querySelector(`.number-item[data-num="25"]`)
					.firstElementChild.setAttribute("disabled", "");
			}

			// Disabilita 1 maggio 2023
			if (calendar.getMonth() == 4 && calendar.getFullYear() == 2023) {
				document
					.querySelector(`.number-item[data-num="1"]`)
					.firstElementChild.setAttribute("disabled", "");
			}

			// Disabilita 1 giugno 2023
			if (calendar.getMonth() == 5 && calendar.getFullYear() == 2023) {
				document
					.querySelector(`.number-item[data-num="2"]`)
					.firstElementChild.setAttribute("disabled", "");
			}

			// Disabilita 15 agosto 2023
			if (calendar.getMonth() == 7 && calendar.getFullYear() == 2023) {
				document
					.querySelector(`.number-item[data-num="15"]`)
					.firstElementChild.setAttribute("disabled", "");
			}

			// Disabilita 1 novembre 2023
			if (calendar.getMonth() == 10 && calendar.getFullYear() == 2023) {
				document
					.querySelector(`.number-item[data-num="1"]`)
					.firstElementChild.setAttribute("disabled", "");
			}

			// Disabilita 8, 26 dicembre 2023
			if (calendar.getMonth() == 11 && calendar.getFullYear() == 2023) {
				document
					.querySelector(`.number-item[data-num="8"]`)
					.firstElementChild.setAttribute("disabled", "");

				document
					.querySelector(`.number-item[data-num="26"]`)
					.firstElementChild.setAttribute("disabled", "");
			}
		},
		attachEventsOnNextPrev: function () {
			calendarControl.plotDates();
			calendarControl.attachEvents();
			calendarControl.addWeekEndDay();
			calendarControl.disabledOldDays();
		},
		init: function () {
			calendarControl.plotSelectors();
			calendarControl.plotDates();
			calendarControl.attachEvents();
			calendarControl.addWeekEndDay();
			calendarControl.disabledOldMonth();
			calendarControl.disabledOldDays();
			calendarControl.plotDayNames();
		},
	};
	calendarControl.init();
}

const calendarControl = new CalendarControl();

window.addEventListener("DOMContentLoaded", (event) => {
	// header_sticky_init();
	headerSectionShadow();
	parallaxEffect();
	calendarSameHeight();

	headerScroll();

	AOS.init({
		duration: 1000,
	});

	ctaFixed();

	bookingWrapper.style.height = `${calendarInputWrapper.firstElementChild.offsetHeight}px`;
});

window.addEventListener("resize", (event) => {
	calendarSameHeight();
});

window.addEventListener("scroll", (event) => {
	// header_sticky_init();
	headerSectionShadow();
	headerScroll();
});
